import { Context } from '@growthbook/growthbook';

export const development: Context = {
  clientKey: 'sdk-drAfMf7Qd3jF9Cvc',
  enableDevMode: true,
  disableCache: true,
};

export const production: Context = {
  clientKey: 'sdk-Cp30MODyJUeDHx',
  enableDevMode: false,
};
