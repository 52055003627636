import {GrowthBook} from '@growthbook/growthbook';
import {detectDevice} from './detect-device';
import type {Context} from '@growthbook/growthbook';
import type {GrowthBookAttributes, TreatwellAttributes} from './types';

export type {GrowthBookAttributes, TreatwellAttributes};

type Options = {
  growthbookAttributes?: GrowthBookAttributes;
};

export async function growthbookInitialiseClient(
  growthbookContext: Context,
  deviceId: string,
  {growthbookAttributes = {}}: Options = {},
) {
  const growthbook = new GrowthBook(growthbookContext);
  const platform = detectDevice(navigator.userAgent);
  const attributes: TreatwellAttributes = {
    ...growthbookAttributes.channel,
    ...growthbookAttributes.user,
    growthbook_id: deviceId,
    platform,
  };

  growthbook.setAttributes(attributes);

  // give GrowthBook two seconds to initialise. In case of error it does not throw
  // but instead just sets all features etc to null
  // https://docs.growthbook.io/lib/js#error-handling
  await growthbook.init({timeout: 2000});
  return growthbook;
}
